import header_main_img from "../assets/images/AdobeStock_339503989.jpeg";
import about_main_img from "../assets/images/AdobeStock_339503989.jpeg";

import bernardo_img from "../assets/images/Bernardo.jpg";
import sander_img from "../assets/images/Sander.jpg";

import logo_img from "../assets/images/logo.png";

const images = {bernardo_img, sander_img, header_main_img, about_main_img, logo_img};

export default images;